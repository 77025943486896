import React from 'react';
import posed from 'react-pose';
import PropTypes from 'prop-types';
import { Button, Card, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import { FormattedMessage, Link as LinkIntl, useIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './BlogCards.css';

const HoverBox = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.02,
    transition: { duration: 500 },
  },
});

const BlogCards = (props) => {
  const intl = useIntl();
  const postsMaybeWithoutFirst = !props.noFilter ? props.posts.slice(1) : props.posts;
  const classes = props.sidebar ? 'mb-60 col-12' : 'mb-60 col-12 col-lg-4';

  return postsMaybeWithoutFirst.map((post) => {
    const fluidImage = post.frontmatter.image.childImageSharp.fluid;
    post.frontmatter.date = intl.locale === 'en' ? post.frontmatter.dateEn : post.frontmatter.dateDe;

    return (
      <Col key={post.fields.slug} className={classes}>
        <LinkIntl to={`/blog/${post.fields.slug}/index.html`}>
          <HoverBox className="h-100">
            <Card className="borderless h-100 blog-card">
              <Img fluid={fluidImage} alt={post.frontmatter.title} className="blog-card-image" />
              <Card.Body className="d-flex flex-column">
                <div className="blog-card-title">
                  <h2 dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />{' '}
                </div>
                <div className="blog-card-date">
                  <span>{post.frontmatter.date}</span>
                </div>
                <div className="blog-card-abstract">
                  <span>
                    {post.frontmatter.abstract
                      ? `${post.frontmatter.abstract.replace(/^(.{120}[^\\s]*).*/, '$1')}...`
                      : post.excerpt}
                  </span>
                </div>
                <Button variant="link" className="blog-card-button">
                  <FormattedMessage id="buttons.learn_more" />
                  <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '.7rem' }} />
                </Button>
              </Card.Body>
            </Card>
          </HoverBox>
        </LinkIntl>
      </Col>
    );
  });
};

BlogCards.propTypes = {
  posts: PropTypes.array.isRequired,
  sidebar: PropTypes.bool,
  noFilter: PropTypes.bool,
};

BlogCards.defaultProps = {
  sidebar: false,
  noFilter: true,
};

export default BlogCards;
